import * as React from "react"
import Seo from "../components/seo"
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'
import logo from './../images/logo.svg'
import icon_insta from './../images/icon_insta.svg'
import icon_tiktok from './../images/icon_tiktok.svg'

const QRCodePage = ({ }) => {

  const data = useStaticQuery(
    graphql`
        query {
          hero1: file(relativePath: { eq: "hero2.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          hero2: file(relativePath: { eq: "hero3.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          hero3: file(relativePath: { eq: "hero4.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }          
          hero4: file(relativePath: { eq: "hero5.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          hero6: file(relativePath: { eq: "hero6.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          hero7: file(relativePath: { eq: "hero7.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          hero8: file(relativePath: { eq: "hero8.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `
  )

  const [count, setCount] = React.useState(1);
  const [imgData, setImageData] = React.useState(data.hero1.childImageSharp.fluid);

  const keyLength = Object.keys(data).length
  console.log(keyLength)

  React.useEffect(() => {
    setTimeout(() => {
      if (count === (keyLength - 1)) {
        setCount(0);
      } else {
        setCount(count + 1);
      }
      console.log(count)
      setImageData(data[Object.keys(data)[count]].childImageSharp.fluid);

    }, 3000);

  }, [count])



  // Set ImageData.

  return (
    <BackgroundImage
      Tag="section"
      className={'h-screen flex flex-col w-full justify-center items-center pt-16 pb-32'}
      fluid={imgData}
      backgroundColor={`#040e18`}
    >

      <img src={logo} className="flex w-32 mb-auto" alt="Logo yourmomentfactory" />

      <ul>
        <li className="bg-black rounded-lg mb-4">
          <a href="https://instagram.com/yourmomentfactory" className="text-white flex items-center p-4">
            <img src={icon_insta} className="w-8 h-8 flex mr-4" alt="Logo yourmomentfactory" />
            @yourmomentfactory
          </a>
        </li>
        <li className="bg-black rounded-lg">
          <a href="https://tiktok.com/@yourmomentfactory" className="text-white flex items-center p-4">
            <img src={icon_tiktok} className="w-8 h-8 flex mr-4" alt="Logo yourmomentfactory" />
            @yourmomentfactory
          </a>
        </li>
      </ul>

    </BackgroundImage>
  )
}

export const Head = () => <Seo title="QR Code" description={'Linklist'} />

export default QRCodePage
